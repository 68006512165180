.menu{
  width: 300px;
  height: 100vh;
  background-color: rgb(30,1,43);
  position:fixed;
  top:0;
  right: -300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
}
.menu.active{
  /* width: 300px;
  height: 100vh;
  background-color: rgb(30,1,43);
  position:fixed;
  top:0; */
  right: 0;
  /* z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

ul {
  margin : 0;
  padding : 0;
  list-style : none;
  font-size: 30px;
  font-weight: 300;
  color: white;
  width: 60%;
}
.list{
  margin-bottom: 20px;
}
.listAnchor{
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.listAnchor:hover{
  font-weight: 500;
}