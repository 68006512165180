.about{
  background-color: white;
  display: flex;
}
.aboutLeft{
  flex: 0.5;
  overflow: hidden;
  display: flex;
  background-color: white;
  flex-direction: column; 
  align-items: center;
  justify-content: space-between;
  
}
.aboutTitle{
  color: lightblue;
  font-size: 50px;
  
}
.aboutP{
  margin-left: 10px;
  font-size: 20px;
}

.aboutRight{
  flex: 0.5;
  position: relative;
  background-color: white;
} 
.aboutImageContainer{
  width:700px;
  height: 700px;
  background-color:lightblue;
  border-radius: 50%;
  display: flex;
  align-items: flex-end; 
  justify-content:center;
  float:left;
}
.bunny{
  width: 100%;
} 
@media (max-width: 500px) {
  .about {
    flex-direction:column;
    align-items: center;
  }
  .aboutImageContainer{
    align-items: flex-start; 
  }
}