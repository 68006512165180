@import "../../global.css";

.topBar{
  width: 100%;
  height: 70px;
  background-color:white;
  color: rgb(30, 1, 43);
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
}

.topBar.active{
  width: 100%;
  height: 70px;
  background-color:rgb(30, 1, 43);
  color: white;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
}

.topBarWrapper{
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topBarLeft{
  display: flex;
  align-items: center;
}

.logo{
  font-size: 40px;
  font-weight: 700;
  text-decoration: none;
  margin-right: 30px;
  color:inherit

}

.emm{
  letter-spacing: -10px;
}

.itemContainer{
  display:flex;
  align-items:center;
  margin-left: 30px;
}
.icon{
  font-size:18px;
  margin-right: 5px;
  color: orange;
}   

.phone, .email{
  font-size: 15px;
  font-weight:500;
}

.linkedIn{
  color: inherit;
  text-decoration: none;

}

.hamburger{
  width:32px;
  height: 25px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.line1,
 .line2,
  .line3{
  width: 100%;
  height: 3px;
  background-color: rgb(30, 1, 43);
  transform-origin: left;
  transition: all 2s ease;
} 

.line1.active{
  background-color:white;
  transform: rotate(45deg);
}

.line2.active{
  opacity:0;
}

.line3.active{
  background-color:white;
  transform: rotate(-45deg);

}

@media (max-width: 500px) {
  .itemContainer {
    display: none;
  }
}