.intro{
  background-color: white;
  display: flex;
}
.introLeft{
  flex: 0.5;
  overflow: hidden;
  /* background-color: orange; */
}

.introRight{
  flex: 0.5;
  position: relative;
} 
.imgContainer{
  width:700px;
  height: 700px;
  background-color:rgb(235, 194, 118);
  border-radius: 50%;
  display: flex;
  align-items: flex-end; 
  justify-content: center;
  float:right;
}
.headShot{
  width: 70%;
}  
.introWrapper{
  width: 100%;
  height: 100%;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#introH1{
  font-size: 60px;
  margin: 10px 0;
}
#introH2{
  font-size: 35px;
}
#introH3{
font-size: 30px;
}
.iCan{
  font-size: 30px;
  color: rgb(235, 194, 118);
}
#introArrow{
  position: absolute; 
  bottom:10px;
  left: 40%;
}

#introArrowImg{
  width: 30px;
  /* animation: arrowBlink 2s infinate; */
}
@media (max-width: 500px) {
  .intro {
    flex-direction:column;
    align-items: center;
  }
  .imgContainer {
    align-items: flex-start;
    margin-top: 20px;
  }
  .headShot {
    height: 50%;
    width: 40%;
  }
  .introWrapper {
    padding-left: 0;
    align-items: center;
  }
  #introH1 {
    font-size: 40px;
  }
  #introH3 {
    font-size: 20px;
  }
}
/* @media (max-width: 500px) {

} */