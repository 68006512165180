.works{
  background-color: rgb(235, 194, 118);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

}

.arrow {
  height: 50px;
  position: absolute;
  cursor: pointer;
}

.arrow.left {
  left: 100px;
  transform: rotateY(180deg);
}

.arrow.right {
  right: 100px;
}

.slider {
  height: 350px;
  display: flex;
  position: absolute;
  left: 0;
  transition: all 1s ease-out;
}

.worksContainer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item {
  width: 700px;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.worksLeft {
  flex: 4;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.worksLeftContainer {
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.worksImgContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(242, 216, 167);
  display: flex;
  align-items: center;
  justify-content: center;
}


.iconImg {
  width: 25px;
}

.projectTitle {
  font-size: 20px;
}

.projectDesc {
  font-size: 13px;
}

.link {
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.worksRight {
  flex: 8;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.worksRightImg {
  width: 400px;
  transform: rotate(-10deg);
}
@media (max-width: 500px) {
  .arrow.left, .arrow.right {
    display:none;
  }
  .slider {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
  }
  .item {
    width: 80%;
    height: 150px;
    margin: 15px 0;
  }
  .worksImageContainer {
    width: 20px;
    height: 20px;
  }
  .iconImage{
    width: 15px;
  }
  .projectTitle {
    font-size: 13px;
  }
  .projectDesc{
    display:none;
  }
}
