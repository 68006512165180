.app{

  height: 100vh;

}

.sections{
  width: 100%;
  height: calc(100vh - 70px); 
  background-color: orange;
  position: relative;
  top: 70px;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  /* scroll-snap-align: start; */
  scrollbar-width: none;
  
  
}  
.sections::-webkit-scrollbar {
  display: none;
}

.sections>* {
  width: 100vw;
  height: calc(100vh - 70px);
  /* scroll-snap-type: y mandatory; */
  scroll-snap-align: start;
}